import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=204cb487&scoped=true&lang=pug&"
import script from "./confirm.vue?vue&type=script&lang=js&"
export * from "./confirm.vue?vue&type=script&lang=js&"
import style0 from "./confirm.vue?vue&type=style&index=0&id=204cb487&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204cb487",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\git\\front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('204cb487')) {
      api.createRecord('204cb487', component.options)
    } else {
      api.reload('204cb487', component.options)
    }
    module.hot.accept("./confirm.vue?vue&type=template&id=204cb487&scoped=true&lang=pug&", function () {
      api.rerender('204cb487', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/inquiry_form/confirm.vue"
export default component.exports